import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import Shape from "./shape"
import Theme from "../../theme/theme"

const DoubleShapeElement = styled.span`
  position: absolute;
  top: 41%;
  left: 0;
  width: 85rem;
  z-index: -1;
`

const StyledShape4 = styled(Shape)`
  display: block;
  max-width: initial;
  z-index: -1;
  transform: translate3d(5rem, -50%, 0);

  path {
    stroke: ${props => props.color};
  }
`

const StyledShape5 = styled(Shape)`
  position: absolute;
  top: 5%;
  left: 20%;
  max-width: initial;
  z-index: -2;
  transform: translate3d(0, -50%, 0);

  path {
    stroke: ${props => props.color};
  }
`

const DoubleShape = ({
  className,
  firstColor = Theme.colors.main,
  secondColor = Theme.colors.second,
  inView = false,
  isAnimated = false,
}) => {
  return (
    <DoubleShapeElement className={className}>
      <StyledShape4
        type={4}
        color={firstColor}
        inView={inView}
        isAnimated={isAnimated}
        delay={200}
      />
      <StyledShape5
        type={5}
        color={secondColor}
        inView={inView}
        isAnimated={isAnimated}
        delay={200}
      />
    </DoubleShapeElement>
  )
}

DoubleShape.propTypes = {
  className: PropTypes.string,
  firstColor: PropTypes.string,
  secondColor: PropTypes.string,
}

export default DoubleShape
