import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import styled from "@emotion/styled"
import { GatsbyImage } from "gatsby-plugin-image"
import { useInView } from "react-cool-inview"

const StyledGatsbyImageWrapper = styled.div`
  max-height: 100%;

  ${props =>
    props.hasBottomLeftRadius &&
    `
    border-bottom-left-radius: 12rem;
  `}

  ${props =>
    props.hasBottomRightRadius &&
    `
    border-bottom-right-radius: 12rem;
  `} 
  
  ${props =>
    props.hasTopLeftRadius &&
    `
    border-top-left-radius: 12rem;
  `} 
  
  ${props =>
    props.hasTopRightRadius &&
    `
    border-top-right-radius: 12rem;
  `}
  
  ${props =>
    props.$isLogo &&
    `
    width: 22rem;
    
    @media (min-width: ${props.theme.devices.desktopMedium}) {
     width: 26rem;
    }   
     
   @media (min-width: ${props.theme.devices.desktopLarge}) {
     width: 32rem;
    }
    
    .gatsby-image-wrapper [data-main-image]{
      object-fit: contain !important;
    }
  `}
  
  .img-svg {
    display: inline-block;
    max-height: 100%;
    width: 100%;
  }

  .gatsby-image-wrapper {
    height: 100%;
    max-height: 100%;
  }

  @media (min-width: ${props => props.theme.devices.desktop}) {
    ${props =>
      props.$isAnimated &&
      `
    opacity:0;
    transform: translate3d(0, 5rem,0);
    transition: 200ms linear;
    
    ${
      props.$inView &&
      `
      opacity: 1;
      transform: translate3d(0,0,0);
    `
    }
  `}
  }

  > div,
  > div > div {
    height: 100%;
    min-height: 100%;
  }
`

const GatsbyImageComponent = ({
  className = "",
  extraClass,
  isAnimated = false,
  isParallax = false,
  isLogo = false,
  hasBottomLeftRadius = false,
  hasBottomRightRadius = false,
  hasTopLeftRadius = false,
  hasTopRightRadius = false,
  image = {},
  alt = "",
}) => {
  const innerRef = useRef()
  const outerRef = useRef()
  const { observe, inView } = useInView({
    threshold: 0.2,
    unobserveOnEnter: true,
  })

  useEffect(() => {
    if (!observe || !innerRef || !isParallax) return

    const containerAnim = gsap.to(innerRef.current, {
      y: "10vh",
      ease: "none",
    })

    const imageAnim = gsap.to(innerRef.current.querySelector("picture img"), {
      y: "20px",
      ease: "none",
      paused: true,
    })

    const scrub = gsap.to(imageAnim, {
      progress: 1,
      paused: true,
      ease: "power3",
      duration: (Math.floor(Math.random() * 6) + 1) / 10,
      overwrite: true,
    })

    const st = ScrollTrigger.create({
      animation: containerAnim,
      scrub: true,
      trigger: outerRef.current,
      start: "top bottom",
      end: "bottom top",
      onUpdate: self => {
        scrub.vars.progress = self.progress
        scrub.invalidate().restart()
      },
    })

    return () => {
      st.kill()
    }
  }, [observe, innerRef, isParallax])

  if (!image?.images) return

  return (
    <StyledGatsbyImageWrapper
      className={`${className} ${extraClass}`}
      $isAnimated={isAnimated}
      ref={observe}
      $inView={inView}
      $isLogo={isLogo}
      hasBottomLeftRadius={hasBottomLeftRadius}
      hasBottomRightRadius={hasBottomRightRadius}
      hasTopLeftRadius={hasTopLeftRadius}
      hasTopRightRadius={hasTopRightRadius}
    >
      <div ref={outerRef}>
        <div ref={innerRef}>
          <GatsbyImage image={image} alt={alt} />
        </div>
      </div>
    </StyledGatsbyImageWrapper>
  )
}

GatsbyImageComponent.propTypes = {
  extraClass: PropTypes.string,
  className: PropTypes.string,
  isAnimated: PropTypes.bool,
  isParallax: PropTypes.bool,
  isLogo: PropTypes.bool,
  isBanner: PropTypes.bool,
  image: PropTypes.object,
  hasBottomLeftRadius: PropTypes.bool,
  hasBottomRightRadius: PropTypes.bool,
  hasTopLeftRadius: PropTypes.bool,
  hasTopRightRadius: PropTypes.bool,
}

export default GatsbyImageComponent
