import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Section from "../../common/section"
import GatsbyImage from "../../common/gatsby-image"
import Shape from "../../svg/shape"
import Theme from "../../../theme/theme"

const StyledSection = styled(Section)`
  position: relative;
  flex-direction: column;
  margin: 0;
`

const IntroContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 11rem;
  padding-bottom: 4rem;
  z-index: 1;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    flex-direction: row;
  }

  @media (min-width: ${props => props.theme.devices.desktopLarge}) {
    padding-bottom: 11rem;
  }

  h1,
  h2 {
    max-width: 95rem;
    font-size: 4rem;
    font-family: "darker_grotesquebold";
    line-height: 4.5rem;
    margin-bottom: 0;

    @media (min-width: ${props => props.theme.devices.desktop}) {
      font-size: 8rem;
      line-height: 8rem;
    }

    @media (min-width: ${props => props.theme.devices.desktopLarge}) {
      font-size: 10rem;
      line-height: 12rem;
    }

    + p {
      display: none;
    }
  }

  p {
    max-width: 50rem;
    font-size: 3.6rem;
    line-height: 4.8rem;
    letter-spacing: 0;
    padding-top: 0;

    @media (max-width: ${props => props.theme.devices.desktop}) {
      font-size: 3rem;
      line-height: 3.4rem;
      margin-top: 3rem;
    }
  }
`

const InfoText = styled.p`
  position: relative;
  max-width: 50rem;
  font-size: 1.8rem;
  line-height: 3.6rem;
  letter-spacing: 0.4rem;
  padding-top: 2rem;
  z-index: 1;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    font-size: 3.6rem;
    line-height: 4.8rem;
    text-align: right;
    margin-left: auto;
  }
`

const StyledShape = styled(Shape)`
  position: absolute;
  top: -2rem;
  left: 40%;
  width: 22rem;
  transform: rotate(180deg);
`

const StyledShape2 = styled(Shape)`
  position: absolute;
  top: 8rem;
  right: -1.7rem;
  width: 31rem;
  z-index: 0;
`

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => {
      return <GatsbyImage image={node.data.target} />
    },
  },
}

const IntroSection = ({ contact }) => {
  return (
    <StyledSection
      pageColor={Theme.colors.invertedText}
      textColor={Theme.colors.color}
      threshold={0.4}
    >
      <IntroContent>
        <div>
          {documentToReactComponents(
            JSON.parse(contact.introText.raw),
            options,
          )}
        </div>
        <InfoText>{contact.info}</InfoText>
      </IntroContent>

      <StyledShape type={1} />
      <StyledShape2 type={1} />
    </StyledSection>
  )
}

IntroSection.propTypes = {
  contact: PropTypes.object,
}

export default IntroSection
