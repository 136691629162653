import React from "react"
import IntroSection from "./intro-section"
import ContactSection from "./contact-section"
import FormSection from "./form-section"

const ContactContent = ({ pageContext }) => {
  return (
    <>
      <IntroSection contact={pageContext.contactPage} />
      <ContactSection contact={pageContext.contactPage} />
      <FormSection contact={pageContext.contactPage} />
    </>
  )
}

export default ContactContent
