import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { INLINES } from "@contentful/rich-text-types"
import Section from "../../common/section"
import GatsbyImage from "../../common/gatsby-image"
import ScrollButton from "../../common/scroll-button"
import Theme from "../../../theme/theme"
import Phone from "../../svg/phone"
import Email from "../../svg/email"
import { TRANSLATIONS } from "../../../utils/translations"

const StyledSection = styled(Section)`
  position: relative;
  flex-direction: column;
  margin: 0;
`

const ContactDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 6rem;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    flex-direction: row;
    margin-bottom: 10rem;
  }

  @media (min-width: ${props => props.theme.devices.desktopLarge}) {
    margin-bottom: 17rem;
  }
`

const ContactData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 93rem;
  width: 100%;
  color: ${props => props.theme.colors.invertedText};
  background: ${props => props.theme.colors.text};
  padding: 2.5rem 4rem 5rem;
  border-radius: 20rem;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    flex-direction: row;
    padding: 2.5rem 10rem;
  }
`

const StyledGatsbyImage = styled(GatsbyImage)`
  overflow: hidden;
  min-width: 28rem;
  max-width: 28rem;
  min-height: 28rem;
  border-radius: 100%;
  transform: translateZ(0);

  @media (min-width: ${props => props.theme.devices.desktop}) {
    margin-right: 8rem;
  }
`

const ContactContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    align-items: flex-start;
    font-size: 2.6rem;
  }

  @media (min-width: ${props => props.theme.devices.desktopLarge}) {
    font-size: 3.6rem;
  }

  h6 {
    font-size: 3.6rem;
    font-family: "darker_grotesquebold";
    margin: 1rem 0 0;

    + p {
      font-size: 1.8rem;
      letter-spacing: 0.3rem;
      text-transform: uppercase;
      margin-bottom: 4rem;

      @media (max-width: ${props => props.theme.devices.desktop}) {
        letter-spacing: 0.2rem;
        text-align: center;
      }
    }
  }

  a {
    display: inline-block;
    position: relative;
    color: ${props => props.theme.colors.invertedText};
    text-decoration: none;
    font-family: "darker_grotesquebold", sans-serif;
    padding-left: 5rem;
    margin-bottom: 1rem;

    svg {
      position: absolute;
      top: 50%;
      left: 0;
      width: 3rem;
      transform: translate3d(0, -50%, 0);
      margin-top: 0.2rem;
    }
  }
`

const InfoData = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    margin: 0 0 0 auto;
  }
`

const StyledScrollButton = styled(ScrollButton)`
  position: relative;
  max-width: 37rem;
  font-size: 2.8rem;
  font-family: "darker_grotesquebold", sans-serif;
  text-align: center;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    margin-top: 0 !important;
    text-align: right;
    margin-left: 4rem;
    padding-right: 12rem;
  }
`

const options = {
  renderNode: {
    [INLINES.HYPERLINK]: ({ data }, children) => (
      <a href={data.uri}>
        {data.uri.startsWith("tel") && <Phone />}{" "}
        {data.uri.startsWith("mail") && <Email />}
        {children}
      </a>
    ),
  },
}

const ContactSection = ({ contact }) => {
  return (
    <StyledSection
      pageColor={Theme.colors.invertedText}
      textColor={Theme.colors.color}
      threshold={0.4}
    >
      <ContactDataWrapper>
        <ContactData>
          {contact.contactImage?.gatsbyImageData && (
            <StyledGatsbyImage image={contact.contactImage.gatsbyImageData} />
          )}
          <ContactContent>
            {documentToReactComponents(
              JSON.parse(contact.contactData.raw),
              options,
            )}
          </ContactContent>
        </ContactData>
        <InfoData>
          <StyledScrollButton sectionId="form-section">
            {TRANSLATIONS[process.env.GATSBY_CONTENTFUL_LOCALE].contactInfo}
          </StyledScrollButton>
        </InfoData>
      </ContactDataWrapper>
    </StyledSection>
  )
}

ContactSection.propTypes = {
  contact: PropTypes.object,
}

export default ContactSection
