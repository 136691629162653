import React from "react"
import PropTypes from "prop-types"

const Check = ({ className }) => {
  return (
    <svg
      className={className}
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="13.162"
      height="9.949"
      viewBox="0 0 13.162 9.949"
    >
      <path
        d="M4696.126,1780l4.273,4.274,6.767-6.767"
        transform="translate(-4695.065 -1776.446)"
        fill="none"
        stroke="#346a50"
        strokeWidth="3"
      />
    </svg>
  )
}

Check.propTypes = {
  className: PropTypes.string,
}

export default Check
