import React from "react"
import Viewport from "../../components/common/viewport"
import ContactContent from "../content/contact/contact-content"
import SEO from "../seo"

const Contact = props => {
  const page = props.pageContext.contactPage

  if (!page) return

  return (
    <Viewport>
      <ContactContent pageContext={props.pageContext} />
    </Viewport>
  )
}

export default Contact

export const Head = ({ location, pageContext }) => (
  <SEO
    pathname={location.pathname}
    title={pageContext.contactPage.seoTitle}
    description={pageContext.contactPage.seoDescription}
    keywords={pageContext.contactPage.seoKeywords}
  />
)
